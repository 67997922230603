import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, IconButton } from '@clarke-energia/foton-v2';

import { EconomyBarChartsProps } from './types';

import EconomyChart from '@components/molecules/economy-report/economy-chart';

import style from './style.module.css';

export const DashboardEconomyChart: React.FC<EconomyBarChartsProps['economyChart']> = ({
  title,
  onChange,
  freeMarket,
  conventionalMarket,
  economy,
  loading,
}) => {
  return (
    <div className={style.economyChart}>
      {loading ? (
        <Skeleton height={350} />
      ) : (
        <button onClick={() => onChange && onChange()} className="w-full h-full">
          <Card.Root className="py-6 px-6 xl:py-7">
            <Card.Header className={style.cardHeader}>
              <Card.Title title={title} />
              <IconButton
                icon="EyeIcon"
                kind="primary"
                onClick={() => onChange && onChange()}
                className="bg-primary-30"
              />
            </Card.Header>
            <EconomyChart
              conventionalMarket={conventionalMarket}
              freeMarket={freeMarket}
              economy={Math.round(economy)}
            />
          </Card.Root>
        </button>
      )}
    </div>
  );
};
