import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';
import Layout from '@components/templates/screen-layout';
import ClarkeLogo from '@assets/clarke-logo-horizontal.svg';
import ClarkeLogoMobile from '@assets/clarke-logo-light.svg';

import { Group } from '@contexts/users/types';

import { useUserInfo } from '@hooks/use-user-info';
import { useGetMigrationReportByGroup } from '@hooks/migration-report/use-migration-report-by-group';
import { useLastEconomyReportByGroup } from '@hooks/get-last-economy-report/use-last-economy-reports';

import ExportButton from '@components/molecules/button/export-button';
import Icon from '@components/molecules/dashboard/icon';
import Migration from '@components/organisms/dashboard/migration';
import Economy from '@components/organisms/dashboard/economy';

const Dashboard: React.FC = () => {
  const [unitId, setUnitId] = useState<string>('');
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const navigate = useNavigate();
  const exportRef = useRef<HTMLDivElement>(null);
  const { user } = useUserInfo();
  const {
    data,
    loading,
    getLastGroupEconomyReport,
    getLastUnitEconomyReport,
    getLastAccumulatedReport,
    groupError,
    unitError,
  } = useLastEconomyReportByGroup();

  const {
    data: migrationData,
    loading: migrationLoading,
    getMigrationReportByGroupHandler,
  } = useGetMigrationReportByGroup();

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.PAGE_VIEW_DASHBOARD);
  }, []);

  const loadReports = async (group: Group) => {
    getLastAccumulatedReport({
      variables: {
        groupId: group.id,
      },
    });

    await getLastGroupEconomyReport({
      variables: {
        groupId: group.id,
      },
    });

    if (groupError) {
      const units = group.units;

      if (units.length === 1) {
        setUnitId(units[0].id);
      }

      for (const unit of units) {
        await getLastUnitEconomyReport({
          variables: {
            unitId: unit.id,
          },
        });

        if (!unitError) return;
      }

      getMigrationReportByGroupHandler(group.id);
    }
  };

  React.useEffect(() => {
    if (user.group?.id) {
      loadReports(user.group);
    }
  }, [user]);

  React.useEffect(() => {
    const economyLoad = !data.date && !migrationData;

    if (!user.id || loading || migrationLoading || economyLoad) {
      setLoadingData(true);
    } else {
      setLoadingData(false);
    }
  }, [user, loading, migrationLoading, data.date, migrationData]);

  return (
    <Layout.Content
      dashboardStyle={{
        children: (
          <>
            <Icon src={ClarkeLogo} className="hidden lg:block" />
            <Icon src={ClarkeLogoMobile} className="block lg:hidden" />
          </>
        ),
      }}
      header={{
        title: '',
        displayDesktop: false,
        additionalButtons: (
          <ExportButton
            className="lg:mr-0"
            exportRef={exportRef}
            exportName="dashboard"
            kind="secondary"
            eventTrackerLabel="BUTTON_EXPORT_DASHBOARD"
            onExportExtraClassnames="bg-greenGradient"
            exportStyleSheet=".dashboard-gauge-chart { background-color: #bef4c1; opacity: 0.7; }"
          />
        ),
      }}
    >
      <div ref={exportRef}>
        {migrationData && !data.totalCosts.labels.length ? (
          <Migration
            loading={loadingData}
            navigate={navigate}
            totalMigrationPercentage={migrationData?.getMigrationReportByGroupId?.conclusionPercentage}
          />
        ) : (
          <Economy data={data} loading={loadingData} navigate={navigate} unitId={unitId} />
        )}
      </div>
    </Layout.Content>
  );
};

export default Dashboard;
