import { useLazyQuery } from '@apollo/client';

import { EnergyTypeEnum } from '@hooks/get-contracts/types';
import { UnitReportParsed, SummaryEconomyReportsHistory, GroupAccumulatedEconomyReportByGroupIdParsed } from './types';

import {
  GroupAccumulatedEconomyReportByGroupIdGraphQLData,
  GroupAccumulatedEconomyReportByGroupIdQueryVariables,
  GroupAccumulatedEconomyReportByUnitIdGraphQLData,
  GroupAccumulatedEconomyReportByUnitIdQueryVariables,
} from './schemas';

import {
  QUERY_GET_ACCUMULATED_ECONOMY_REPORTS_BY_UNIT_ID,
  QUERY_GET_GROUP_ACCUMULATED_ECONOMY_REPORT_BY_GROUP_ID,
} from './queries';
import { formatUnitsAccumulatedSummaryEconomyReportsFromGroup, parseGroupAccumulatedEconomyReport } from './parser';
import { GroupAccumulatedEconomyReportManager } from './manager';

export const useGetGroupAccumulatedEconomyReport = () => {
  const parsedData: {
    parsedGroupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupIdParsed;
    energyType: EnergyTypeEnum | string;
    parsedUnitAccumulatedSummaryReport: UnitReportParsed[];
    rawSummaryEconomyReportsHistory?: SummaryEconomyReportsHistory[];
  } = {
    parsedGroupAccumulatedEconomyReport: {} as GroupAccumulatedEconomyReportByGroupIdParsed,
    energyType: '-',
    parsedUnitAccumulatedSummaryReport: [],
    rawSummaryEconomyReportsHistory: [],
  };

  const [getGroupAccumulatedEconomyReportByGroupId, { data: groupData, loading: groupLoading, error: groupError }] =
    useLazyQuery<
      GroupAccumulatedEconomyReportByGroupIdGraphQLData,
      GroupAccumulatedEconomyReportByGroupIdQueryVariables
    >(QUERY_GET_GROUP_ACCUMULATED_ECONOMY_REPORT_BY_GROUP_ID);

  const [getGroupAccumulatedEconomyReportByUnitId, { data, loading, error }] = useLazyQuery<
    GroupAccumulatedEconomyReportByUnitIdGraphQLData,
    GroupAccumulatedEconomyReportByUnitIdQueryVariables
  >(QUERY_GET_ACCUMULATED_ECONOMY_REPORTS_BY_UNIT_ID);

  if (groupData) {
    const manager = new GroupAccumulatedEconomyReportManager(groupData);

    parsedData.parsedGroupAccumulatedEconomyReport = parseGroupAccumulatedEconomyReport(
      manager.groupAccumulatedEconomyReport,
    );
    parsedData.parsedUnitAccumulatedSummaryReport = formatUnitsAccumulatedSummaryEconomyReportsFromGroup(
      manager.groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports ?? [],
    );
    parsedData.rawSummaryEconomyReportsHistory = manager.groupAccumulatedEconomyReport.summaryEconomyReportsHistory;
    parsedData.energyType = '';
  }

  if (data) {
    parsedData.parsedGroupAccumulatedEconomyReport = parseGroupAccumulatedEconomyReport(
      data.getAccumulatedEconomyReportByUnitID,
    );
    parsedData.rawSummaryEconomyReportsHistory = data.getAccumulatedEconomyReportByUnitID.summaryEconomyReportsHistory;
    parsedData.energyType = '';
  }

  return {
    getGroupAccumulatedEconomyReportByUnitId,
    getGroupAccumulatedEconomyReportByGroupId,
    data: data || groupData,
    parsedData,
    loading: loading || groupLoading,
    error: error || groupError,
    groupLoading,
    unitLoading: loading,
    groupError,
    unitError: error,
  };
};
