import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, AreaChart, IconButton, LineChart } from '@clarke-energia/foton-v2';

import { EconomyBarChartsProps } from './types';

import style from './style.module.css';

export const DashboardCostChart: React.FC<EconomyBarChartsProps['costChart']> = ({
  title,
  onChange,
  labels,
  series,
  lines,
  loading,
}) => {
  const newSeries = series.map((serie: any) => ({
    ...serie,
    color: '#00BF6A',
    colors: {
      start: '#96E9B6',
      end: '#1AFFE40D',
    },
    width: 7,
  }));

  return (
    <div className={style.fullEconomyBarChart}>
      {loading ? (
        <Skeleton height={350} />
      ) : (
        <button onClick={() => onChange && onChange()} className="w-full h-full">
          <Card.Root className="h-full shadow-md">
            <Card.Header className={style.cardHeader}>
              <Card.Title title={title ?? ''} />
              <IconButton
                icon="EyeIcon"
                kind="primary"
                onClick={() => onChange && onChange()}
                className="bg-primary-30"
              />
            </Card.Header>
            {series.length === 1 ? (
              <LineChart
                title=""
                series={newSeries}
                labels={labels}
                lines={[]}
                options={{
                  legend: { show: true },
                  rightSpace: 0,
                  leftSpace: 30,
                  yAxisLabel: {
                    decimalPlaces: 2,
                  },
                }}
              />
            ) : (
              <AreaChart
                labels={labels}
                series={newSeries}
                lines={lines}
                options={{ legend: { show: true }, autoScale: true, prefix: 'R$ ' }}
              />
            )}
          </Card.Root>
        </button>
      )}
    </div>
  );
};
