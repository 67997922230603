import numeral from 'numeral';
import { $dayjs } from '@utils/dayjs';

import { GroupReportGraphQL, Report, UnitReportGraphQL, Direction, AccumulatedReportGraphQL } from './types';
import { upperFirstLetter } from '@utils/text';

type FormattedReport = Omit<Report, 'totalCosts' | 'accumulatedSaving'>;

export const formatGroupReport = (report: GroupReportGraphQL['lastGroupEconomyReportByGroupId']): FormattedReport => {
  const totalEconomy = sumTotalFreeAndConventionalMarkets(report.economyReports);
  return {
    date: report.date,
    conventionalMarketTotal: numeral(10).format('$ 0,0.00'),
    freeMarketTotal: numeral(20).format('$ 0,0.00'),
    economy: {
      total: numeral(report.totalMonthlySavings).format('$ 0,0.00'),
      percentage: numeral(report.savingBalancePercent / 100).format('0.00%'),
      direction: getPercentageType(report.savingBalancePercent),
    },
    energy: {
      consumption: {
        total: `${numeral(report.totalMonthlyEnergyConsumption).format('0,0.00')} MWh`,
        percentage: numeral(report.energyConsumptionBalancePercent / 100).format('0.00%'),
        direction: getPercentageType(report.energyConsumptionBalancePercent),
      },
      cost: {
        total: numeral(report.totalMonthlyEnergyCost).format('$ 0,0.00'),
        percentage: numeral(report.energyCostBalancePercent / 100).format('0.00%'),
        direction: getPercentageType(report.energyCostBalancePercent),
      },
    },
    totalSaving: report.totalMonthlySavingsPercent,
    totalEconomy: {
      conventionalMarket: numeral(totalEconomy.conventionalMarket).format('$ 0,0.00'),
      freeMarket: numeral(totalEconomy.freeMarket).format('$ 0,0.00'),
    },
  };
};

export const formatUnitReport = (report: UnitReportGraphQL['lastEconomyReportByUnitId']): FormattedReport => {
  return {
    date: report.date,
    conventionalMarketTotal: numeral(10).format('$ 0,0.00'),
    freeMarketTotal: numeral(20).format('$ 0,0.00'),
    economy: {
      total: numeral(report.currentSavings).format('$ 0,0.00'),
      percentage: numeral(report.savingBalancePercent / 100).format('0.00%'),
      direction: getPercentageType(report.savingBalancePercent),
    },
    energy: {
      consumption: {
        total: `${numeral(report.energyTotalConsumption).format('0,0.00')} MWh`,
        percentage: numeral(report.energyConsumptionBalancePercent / 100).format('0.00%'),
        direction: getPercentageType(report.energyConsumptionBalancePercent),
      },
      cost: {
        total: numeral(report.energyTotalCost).format('$ 0,0.00'),
        percentage: numeral(report.energyCostBalancePercent / 100).format('0.00%'),
        direction: getPercentageType(report.energyCostBalancePercent),
      },
    },
    totalEconomy: {
      conventionalMarket: numeral(report.conventionalMarket.totalValue).format('$ 0,0.00'),
      freeMarket: numeral(report.freeMarket.totalValue).format('$ 0,0.00'),
    },
    totalSaving: report.savingsPercent,
  };
};

const getPercentageType = (percentage: number): Direction => {
  if (percentage > 0) return 'up';
  if (percentage < 0) return 'down';

  return 'none';
};

const sumTotalFreeAndConventionalMarkets = (
  reports: GroupReportGraphQL['lastGroupEconomyReportByGroupId']['economyReports'],
) => {
  return reports.reduce(
    (acc, report) => {
      acc.freeMarket += report.freeMarket.totalValue;
      acc.conventionalMarket += report.conventionalMarket.totalValue;
      return acc;
    },
    { freeMarket: 0, conventionalMarket: 0 },
  );
};

export const buildChartEconomyReports = (
  reports: AccumulatedReportGraphQL['getLastAccumulatedEconomyReportByGroupId']['summaryEconomyReportsHistory'],
) => ({
  labels: reports.map((report) => upperFirstLetter($dayjs(report.date).format('MMM/YYYY').replace('.', ''))),
  series: [
    {
      values: reports.map((report) => report.monthlyEnergyCost),
      name: 'Custo em R$',
      ...(reports.length === 1
        ? {
            symbol: {
              show: true,
              type: 'circle',
            },
          }
        : { area: true }),
    },
  ],
});
