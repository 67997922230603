import React from 'react';

import { buildMigrationCards } from '@components/molecules/dashboard/helper';

import SectionTextIcon from '@components/molecules/dashboard/content-bolt';
import Cards from '@components/molecules/dashboard/migration-card';
import { NavigateFunction } from 'react-router-dom';

interface MigrationProps {
  loading: boolean;
  navigate: NavigateFunction;
  totalMigrationPercentage: number;
}

const Migration: React.FC<MigrationProps> = ({ loading, totalMigrationPercentage, navigate }) => {
  const handleRedirect = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <SectionTextIcon
        loading={loading}
        isMigration
        chart={{
          loading,
          title: { value: 'da migração em andamento', offsetCenter: [0, '60%'], overflow: 'break', width: 180 },
          data: totalMigrationPercentage * 100,
        }}
        text={
          <>
            <p>
              A sua <span className="font-extrabold">migração</span>
              {''} para o Mercado Livre de Energia <span className="font-extrabold">já está em andamento.</span>
            </p>
            <p className="mt-4 text-sm lg:mt-8 lg:text-base xl:w-[50rem]">
              Você encontrará por aqui os dados referentes a sua economia, custo e consumo, assim que concluir o
              processo de migração!
            </p>
          </>
        }
      />
      <Cards loading={loading} cards={buildMigrationCards()} handleRedirect={(path: string) => handleRedirect(path)} />
    </>
  );
};

export default Migration;
