import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  GET_LAST_ACCUMULATED_ECONOMY_REPORT,
  GET_LAST_GROUP_ECONOMY_REPORT,
  GET_LAST_UNIT_ECONOMY_REPORT,
} from './queries';
import { AccumulatedReportGraphQL, GroupReportGraphQL, Report, UnitReportGraphQL } from './types';
import { buildChartEconomyReports, formatGroupReport, formatUnitReport } from './parser';

export const useLastEconomyReportByGroup = () => {
  const [finalData, setFinalData] = useState<Report>({
    date: '',
    conventionalMarketTotal: 'R$0,00',
    freeMarketTotal: 'R$0,00',
    economy: { total: 'R$0,00', direction: 'none', percentage: '' },
    energy: {
      consumption: { total: 'R$0,00', direction: 'none', percentage: '' },
      cost: { total: 'R$0,00', direction: 'none', percentage: '' },
    },
    totalSaving: 0,
    totalEconomy: {
      freeMarket: 'R$0,00',
      conventionalMarket: 'R$0,00',
    },
    totalCosts: {
      labels: [],
      series: [{ values: [0, 0] }],
    },
    accumulatedSaving: 0,
  });

  const [getLastGroupEconomyReport, { data: groupData, loading: groupDataLoading, error: groupError }] =
    useLazyQuery<GroupReportGraphQL>(GET_LAST_GROUP_ECONOMY_REPORT);

  const [getLastUnitEconomyReport, { data: unitData, loading: unitDataLoading, error: unitError }] =
    useLazyQuery<UnitReportGraphQL>(GET_LAST_UNIT_ECONOMY_REPORT);

  const [getLastAccumulatedReport, { data: accumulatedData, loading: accumulatedDataLoading }] =
    useLazyQuery<AccumulatedReportGraphQL>(GET_LAST_ACCUMULATED_ECONOMY_REPORT);

  useEffect(() => {
    if (groupData) {
      const { lastGroupEconomyReportByGroupId } = groupData;
      const formattedData = formatGroupReport(lastGroupEconomyReportByGroupId);

      setFinalData((prev) => ({ ...prev, ...formattedData }));
    }
  }, [groupData]);

  useEffect(() => {
    if (unitData) {
      const { lastEconomyReportByUnitId } = unitData;
      const formattedData = formatUnitReport(lastEconomyReportByUnitId);

      setFinalData((prev) => ({ ...prev, ...formattedData }));
    }
  }, [unitData]);

  useEffect(() => {
    if (accumulatedData?.getLastAccumulatedEconomyReportByGroupId) {
      const {
        getLastAccumulatedEconomyReportByGroupId: { summaryEconomyReportsHistory, totalSavingsPercentage },
      } = accumulatedData;
      const costs = buildChartEconomyReports(summaryEconomyReportsHistory);

      setFinalData((prev) => ({
        ...prev,
        totalCosts: { labels: costs.labels, series: costs.series },
        accumulatedSaving: totalSavingsPercentage,
      }));
    }
  }, [accumulatedData]);

  return {
    getLastGroupEconomyReport,
    getLastUnitEconomyReport,
    getLastAccumulatedReport,
    data: finalData,
    loading: groupDataLoading || unitDataLoading || accumulatedDataLoading,
    error: unitError || groupError,
    groupError,
    unitError,
  };
};
