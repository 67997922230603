import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { twMerge } from 'tailwind-merge';
import { GaugeChart, GaugeChartProps } from '@clarke-energia/foton-v2';

import style from './style.module.css';

type ChartTitle = Omit<GaugeChartProps['title'], 'color' | 'size'>;

export interface ChartProps {
  data: number;
  title: ChartTitle;
  loading: boolean;
}

const ChartGauge: React.FC<ChartProps> = ({ data, loading, title }) => {
  const chartTitle: GaugeChartProps['title'] = {
    value: title.value,
    color: '#005931',
    size: 22,
    offsetCenter: title.offsetCenter,
    overflow: title.overflow,
    width: title.width,
  };

  return loading ? (
    <Skeleton height={250} width={250} className={style.skeletonChart} />
  ) : (
    <div className={twMerge(style.gaugeChartContainer, 'dashboard-gauge-chart')}>
      <GaugeChart
        title={chartTitle}
        amountValue={{ color: '#005931', size: 60 }}
        progress={{ suffix: '%', endAngle: 0, startAngle: 180 }}
        progressLine={{ width: 30 }}
        data={{
          value: data,
          name: '',
          color: { pattern: 'linear', name: '#3490dc' },
        }}
      />
    </div>
  );
};

export default ChartGauge;
